.otp-container {
  height: 100vh;
  padding: 20px;
}
.otp-form {
  margin-top: 10px;
}
.otp-input {
  outline: none;
  text-align: center;
  font-size: 24px;
  border: none;
  border-bottom: 1px solid grey;
  width: 50px;
  margin: 0 10px;
}
.resend-text {
  font-weight: 500;
  margin: 40px 5px;
  font-size: 18px;
}
.otp-footer {
  /* height: 15vh; */
  padding: 14px 20px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  width: 100vw;
}
.otp-edit {
  color: var(--text-color);
  font-size: 18px;
}
