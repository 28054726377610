/* @font-face {
  font-family: "Avenir Next Condensed";
  src: local("Avenir Next Condensed"),
    url("../../fonts/AvenirNextCondensed-Regular.ttf") format("truetype");
} */
@font-face {
  font-family: "Source Sans Pro";
  src: local("Source Sans Pro"),
    url("../../fonts/SourceSansPro-Regular.ttf") format("truetype");
}
.source-sans-pro {
  font-family: "Source Sans Pro" !important;
}
.rounded {
  rx: 20px;
  ry: 20px;
  box-shadow: 0 6px 0 #d6958e, 0 15px 20px rgba(0, 0, 0, 0.35);
  transition: all 0.05s;
}

.diamond {
  rx: 10px;
  ry: 10px;
  transition: all 0.05s;
  transform-box: fill-box;
  transform-origin: center;
  transform: rotate(45deg);
  box-shadow: 4px 4px 0 #ce9e2f, 0 15px 20px rgba(0, 0, 0, 0.35);
}

.rectangle {
  font-size: 1em;
  font-weight: 800;
  padding: 15px 20px;
  color: #000000;
  width: 120px;
  border-radius: 0.5em;
  box-shadow: 0px 4px 0 #74c5d8, 0 15px 20px rgba(0, 0, 0, 0.35);
  transition: all 0.05s;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.square {
  rx: 5px;
  ry: 5px;
  /* box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.82); */
  box-shadow: 0px 5px 10px 5px rgba(214, 214, 214, 0.3);
}
.diamond:hover {
  cursor: pointer;
}
.rounded:hover {
  cursor: pointer;
}
.rectangle:hover {
  cursor: pointer;
}
.square:hover {
  cursor: pointer;
}
.button:focus {
  outline: none;
}
.circle {
  rx: 50px;
  ry: 50px;
}
.button:active {
  transform: translate(0, 6px);
}
.button:active,
.button:focus {
  box-shadow: 0 12px 10px rgba(0, 0, 0, 0.3);
}
.bg-styling {
  background: rgba(255, 255, 255, 0.7);
  border: 2px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 5px 10px 5px rgba(214, 214, 214, 0.3);
  border-radius: 10px;
}
@media only screen and (max-width: 425px) {
  .chart-container {
    zoom: 40%;
  }
}
