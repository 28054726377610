.register-text {
  margin: 10px 0 20px 0;
  color: var(--text-color);
}
.custom-input {
  font-size: 16px;
  border: none;
  outline: none;
  padding: 0 12px;
  width: 100%;
  box-shadow: var(--box-shadow-style);
  height: 40px;
}
.field-container {
  display: flex;
  margin-bottom: 25px;
}
.form-control {
  width: 50%;
  padding: 0 2%;
}
.email-container {
  padding: 0 2%;
}
.register-bottom {
  height: 15vh;
  padding: 12px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  width: 100%;
}
.progress-container {
  margin-left: -30px;
}
.already-text {
  font-size: 12px;
  color: var(--text-color);
  margin-left: 38px;
}
