@font-face {
  font-family: "Avenir Next";
  font-style: normal;
  font-weight: normal;
  /* src: local("Avenir Next"), url("../../fonts/Avenir-Next.woff") format("woff"); */
  src: local("Avenir Next"),
    url("../../fonts/Avenir-Next.woff") format("opentype");
}
@font-face {
  font-family: "Montserrat";
  src: local("Montserrat-ExtraBold"),
    url("../../fonts/Montserrat-ThinItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Source Sans Pro";
  src: local("Source Sans Pro"),
    url("../../fonts/SourceSansPro-Regular.ttf") format("truetype");
}

.modalContent h2 span {
  font-family: "Source Sans Pro" !important;
}
.modalContent p span {
  font-family: "Source Sans Pro" !important;
}
.modalContent ul li span {
  font-family: "Source Sans Pro" !important;
}
.container {
  margin: 20px;
}
.container h2 {
  margin: 10px auto;
}

.container h4 {
  margin: 0px;
}
.line {
  height: 5px;
  border-bottom: 1px dashed #47a4c3;
}
.nav-bar {
  position: fixed;
  margin: 0;
  bottom: 0;
  background-color: #fbfeff;
  width: 100vw;
  height: 50px;
  box-shadow: 0px -2px 15px #c4c4c4;
  display: flex;
  justify-content: space-between;
  z-index: 10;
}
.nav-bar-left-menu,
.nav-bar-right-menu {
  display: flex;
  height: 100%;
  z-index: 20;
}

.nav-button {
  border: none;
  min-height: 100%;
  width: 50px;
  background: none;
}

.nav-button span {
  position: relative;
  top: 25%;
}

.flowchart-container {
  width: 100%;
  margin: auto;
  text-align: center;
  padding-top: 25px;
  padding-bottom: 50px;
  /* overflow:scroll; */
}

.button-name {
  color: #333;
  display: table-cell;
  height: 60px;
  transform: rotate(-45deg);
  vertical-align: middle;
  width: 60px;
}

.modal {
  margin: auto;
  background: #ffffff;
  box-shadow: 0px -2px 15px #c4c4c4;
  position: fixed;
  bottom: 0;
  z-index: 5;
  width: 100%;
  height: 320px;
  transition: ease-in 0.2s;
  padding-bottom: 22px;
  /* opacity: 0;
    visibility: hidden; */
  /* transform: translate(0,100%); */
}

.modalData {
  margin: 10px 20px;
}
.modalData img {
  width: 100%;
}
.modalData iframe {
  min-height: 200px;
  width: 100%;
}

.hidden {
  bottom: -320px !important;
  opacity: 0;
  visibility: hidden;
  display: none;
}
.fullscreen {
  height: 90%;
}
.modalContent {
  width: 100%;
  /* min-height: 250px; */
  overflow: hidden;
  font-size: 0.8em;
  color: #999999;
  padding-top: 10px;
  font-family: "Avenir-Book";
  padding-bottom: 38px;
}
.fullscreen .modalContent {
  height: 450px;
  overflow: scroll;
}
.read-more {
  font-weight: 500;
  position: absolute;
  right: 20;
  font-size: 1.2em;
  margin-top: 20px;
}
.fullscreen .read-more {
  display: none;
}

.side-bar {
  background-color: #fff;
  position: fixed;
  top: 0;
  right: 0vw;
  left: 0vw;
  top: 0vw;
  bottom: 0vw;
  z-index: 50;
  transition: ease-in 0.2s;
  padding: 20px;
  opacity: 0;
  visibility: hidden;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* align-items: flex-end; */
}
.side-bar li {
  padding: 0.8rem 0;
}
.side-bar-visible {
  opacity: 1;
  visibility: visible;
  overflow-y: scroll;
}

li {
  transition: ease-in 0.1s;
}
li:hover {
  padding-right: 10px;
}
.shapeImage {
  padding-left: 60%;
  display: flex;
}
.shape-text {
  font-size: 12px;
  margin-left: 8px;
  color: #999999;
}
.test {
  display: none;
}
.report-modal {
  margin: auto;
  background: #ffffff;
  box-shadow: 0px -2px 15px #c4c4c4;
  position: fixed;
  bottom: 0;
  z-index: 5;
  width: 100%;
  height: 270px;
  transition: ease-in 0.2s;
}
.report-inner h3 {
  color: var(--text-color);
  font-weight: bold;
}
.report-inner {
  margin: 20px 20px;
}
.warning-input {
  border: 1px solid var(--text-color);
  height: 70px;
  width: 100%;
  resize: none;
  padding: 6px;
}
.report-submit {
  background-color: var(--text-color);
  padding: 6px 18px;
  border: none;
  display: block;
  color: #fff;
  margin-top: 5px;
  margin-left: auto;
}

.bottom-container {
  position: fixed;
  width: 100vw;
  bottom: 30px;
  padding: 12px 20px;
  display: flex;
  justify-content: space-between;
  height: 75px;
  background-color: rgb(255, 255, 255);
}
.go-back {
  /* box-shadow: 0px -2px 15px #c4c4c4;
  padding: 0.3rem 1rem; */
  /* border: none; */
  cursor: pointer;
  outline: none;
}
@media only screen and (min-width: 600px) {
  .test {
    display: none !important;
    background-color: red;
  }
  .shapeImage {
    padding-left: 73%;
    display: flex;
  }
  .modal {
    right: 0;
    width: 30%;
    height: 100vh;
    padding-bottom: 0;
  }
  .modalContent {
    height: 74vh;
    overflow-y: scroll;
  }
  .fullscreen .modalContent {
    height: 85vh;
    overflow-y: scroll;
  }
  li:hover {
    padding-right: none;
  }
  .side-bar-visible {
    width: 27%;
  }
  .side-bar {
    right: 0vw;
    left: auto;
    box-shadow: 0px -2px 15px #c4c4c4;
  }
  .bottom-container {
    bottom: 0px;
    margin: 15px 20px 50px 20px;
    height: 60px;
  }
}
.ant-tree .ant-tree-switcher-leaf-line {
  display: none !important;
}
.TransformComponent-module_container__3NwNd {
  width: inherit !important;
  height: 480px !important;
}
.ant-tree-show-line .ant-tree-switcher {
  background: transparent !important;
}
.ant-tree .ant-tree-node-content-wrapper .ant-tree-iconEle {
  display: none !important;
}
