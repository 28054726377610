/* #888 opcity 50%
inset 3 3 20 0
inset fff 100% -3 -3 20 0 */
.custom-inputs {
  font-size: 16px;
  border: none;
  outline: none;
  padding: 0 12px;
  width: 75%;
  box-shadow: var(--box-shadow-style);
  height: 40px;
}
.main-container {
  height: 100vh;
  width: 100%;
}
.design-container {
  height: 54.7vh;
  padding: 25px;
  transition: display 0.35s ease-in-out;
}
.design-img {
  height: 100%;
  width: 100%;
}
.auth-container {
  height: 30vh;
  padding: 30px 15px 25px 15px;
  /* border-bottom: 1px solid grey; */
}
.login-header {
  margin: 10px 0 20px 0;
  color: var(--text-color);
}
.plus {
  font-size: 22px;
  font-weight: 500;
  padding: 0 10px;
}
.terms-container {
  height: 15vh;
  padding: 12px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  width: 100%;
  border-top: 1px solid grey;
}
.submit-container {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  margin-top: -4%;
  justify-content: space-between;
  box-shadow: 12px 12px 24px 0 rgba(0, 0, 0, 0.2),
    -12px -12px 24px 0 rgba(255, 255, 255, 0.5);
}
.submit-inner {
  width: 100%;
  height: 100%;
  text-align: center;
  display: block;
  margin: auto;
  line-height: 62px;
}
