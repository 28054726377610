* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html,
body {
  width: 100%;
  height: 100%;
}

@font-face {
  font-family: "Savoye LET Plain";
  font-style: normal;
  font-weight: normal;
  src: local("Savoye LET Plain"),
    url("../../fonts/Savoye LET Plain1.0.woff") format("woff");
}

.home-container {
  height: 100vh;
  width: 100vw;
}
.logo {
  /* background-color: rgb(189, 189, 189); */
  height: 65px;
  width: 65px;
  /* border-radius: 50%; */
  margin-right: 20px;
}
.logo-img {
  height: 100%;
  width: 100%;
}
.first-home {
  height: 100vh;
}
.home-header {
  height: 100vh;
  background-color: #eeeeee;
  padding-left: 25%;
}
.about-heading {
  padding-top: 7rem;
  padding-left: 3rem;
  color: #a1a1a1;
  font-size: 38px;
  display: flex;
}
.about-inflow {
  font-family: "Savoye LET Plain";
  font-size: 39px;
}
.about-inflow span {
  font-family: "Savoye LET Plain";
  font-size: 39px;
}
.about-sub {
  font-size: 26px;
  margin-top: -5px;
  line-height: 40px;
}
.about-med {
  font-weight: bolder;
  color: #5d9dc7;
}
.about-subheading {
  padding: 1rem;
  color: #909090;
  font-size: 22px;
  margin: 0.1rem 2rem;
  box-shadow: var(--box-shadow-style);
  width: 35%;
}
.tilt-square {
  height: 70px;
  width: 70px;
  margin-top: 0.8rem;
  margin-left: 3rem;
  box-shadow: var(--box-shadow-style);
  transform: rotate(45deg);
}
.top-line {
  margin-top: -3px;
  margin-left: 5.1rem;
  width: 5px;
  height: 60px;
  box-shadow: var(--box-shadow-style);
}
.bottom-line {
  margin-left: 5rem;
  width: 5px;
  height: 60px;
  margin-top: 0.8rem;
  box-shadow: var(--box-shadow-style);
}
.horizontal-line {
  height: 5px;
  width: 437px;
  margin-left: 5rem;
  box-shadow: var(--box-shadow-style);
}
.arrow-container {
  width: 65px;
  background-color: #5d9dc7;
  height: 65px;
  border-radius: 50%;
  border: 5px solid #fff;
  margin-left: 50%;
  margin-top: -4%;
  box-shadow: 4px 2px 24px rgba(255, 255, 255, 0.5),
    10px 10px 15px rgba(70, 70, 70, 0.12);
}
.arrow-inner {
  width: 100%;
  height: 100%;
  text-align: center;
  display: block;
  margin: auto;
  line-height: 60px;
}
.get-started-text {
  font-size: 12px;
  margin-top: 0.5rem;
  text-align: center;
}
.get-started-btn {
  background: #0080ab;
  padding: 0.9rem 2rem;
  border-radius: 8px;
  border: none;
  color: #fff;
  font-size: 18px;
  width: 110px;
  margin: -30px auto 0;
  display: block;
  text-align: center;
}
.get-started-btn:hover {
  color: #fff;
}
/* .second-home {
  height: 20vh;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.contact-us {
  padding: 2rem 3rem;
}
.contact-link {
  color: #555555;
}
.contact-link:hover {
  color: #555555;
} */

@media only screen and (max-width: 480px) {
  .home-header {
    padding-left: 0;
  }
  .about-heading {
    padding-top: 3.5rem;
    padding-left: 2rem;
    font-size: 32px;
  }
  .about-sub {
    font-size: 24px;
    margin-top: 0;
    line-height: 35px;
  }
  .about-subheading {
    /* padding-top: 0rem;
    padding-left: 1.5rem;
    padding-right: 2rem; */
    padding: 1rem;
    font-size: 19px;
    margin: 0.2rem auto;
    width: 70%;
    /* font-weight: 300; */
  }
  .horizontal-line {
    width: 170px;
  }
  .arrow-container {
    margin-left: 66%;
    margin-top: -12%;
  }
}
